import React, { useState } from 'react';
import '../../css/Contact.css';


const ServiceContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error message for the field being edited
    if (value.trim() !== '') {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // Validation for empty fields
    if (value.trim() === '') {
      setErrors({ ...errors, [name]: 'Please complete this required field' });
    }

    // Additional validation for phone number (digits only)
    if (name === 'phone' && value.trim() !== '' && !/^\d+$/.test(value)) {
      setErrors({ ...errors, phone: 'Please enter a valid phone number (digits only)' });
    }
  };

  const validate = () => {
    let newErrors = {};

    // Required field validation
    if (!formData.name.trim()) {
      newErrors.name = 'Please complete this required field';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Please complete this required field';
    }
    if (!formData.company.trim()) {
      newErrors.company = 'Please complete this required field';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Please complete this required field';
    }

    // Phone validation (digits only)
    if (!formData.phone.trim()) {
      newErrors.phone = 'Please complete this required field';
    } 
    if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number (digits only)';
    }

    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Form data submitted:', formData);
      // Handle form submission logic here, such as sending data to a server.
    }
  };

  return (
    <div>
      <div className="contact-form_bg">
        <div className="headlines">
          <h2 className='headline-1'>Let's Discuss Your Project</h2>
          <p className='cont-des'>Contact us today to discuss your project requirements and discover how we can help you achieve your goals. Together, we create an app that stands out in the crowded digital landscape and delivers exceptional value to your users.
          </p>
        </div>
        <div className="main-form">
          <form onSubmit={handleSubmit}>
            <div className={`form-group ${errors.name ? 'error' : ''}`}>
              <label htmlFor="name">Enter your name <span>*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.name ? 'error-input' : ''}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className={`form-group ${errors.email ? 'error' : ''}`}>
              <label htmlFor="email">Email <span>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email ? 'error-input' : ''}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className={`form-group ${errors.phone ? 'error' : ''}`}>
              <label htmlFor="phone">Phone number <span>*</span></label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.phone ? 'error-input' : ''}
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>

            <div className={`form-group ${errors.company ? 'error' : ''}`}>
              <label htmlFor="company">Company name <span>*</span></label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.company ? 'error-input' : ''}
              />
              {errors.company && <span className="error-message">{errors.company}</span>}
            </div>

            <div className={`form-group ${errors.message ? 'error' : ''}`}>
              <label htmlFor="message">Message <span>*</span></label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.message ? 'error-input' : ''}
              ></textarea>
              {errors.message && <span className="error-message">{errors.message}</span>}
            </div>
            <button className='s-btn' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ServiceContact;
