import React,{useState, useEffect} from 'react';
import DM from '../../assets/images/Digital-Market.jpg';
import '../../css/Digital.css';
import ClientLogohead from '../Home/ClientLogohead';
import digital1 from '../../assets/images/digit-imag.png';
import { IoIosStarOutline } from "react-icons/io";
import { MdOutlineCheckCircle } from "react-icons/md";
import { CiFaceSmile } from "react-icons/ci";
import { SlBadge } from "react-icons/sl";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import rating from '../../assets/images/flaticon-rating.png'
import service from '../../assets/images/service-dm.jpg'
import smiley from '../../assets/images/smiley.png'
import badge from '../../assets/images/badge.png'
import logo from '../../assets/images/riologo.png'
import HomeContact from '../Home/HomeContact';
import Accordion from '../Accordion/Accordion';
import Surfer from '../../assets/images/Surfer.png'
import Google from '../../assets/images/Google_Analytics.png'
import GoogleSearch from '../../assets/images/google.png'
import ahrefs from '../../assets/images/ahrefs.png'
import keyword from '../../assets/images/keywords.png'
import semrush from '../../assets/images/semrush.png'
import bp from '../../assets/images/business-people.png'
import plan from '../../assets/images/plan.png'
import time from '../../assets/images/time-management.png'
import sm from '../../assets/images/sm.png'



function Digitalmarketing() {

  const accordionItems = [
    { label: "Branding", content: "With an effective strategy and its implementation, we ensure that the USP or the uniqueness of your business is felt worldwide. Our experts conduct a thorough analysis of your business and target audience to ensure only a single USP of your business is noticed by them. Utilizing social media and other platforms, we help you develop effective communication channels for your business." },
    { label: "Content Marketing", content: "Our content marketing services are most beneficial for developing, editing, writing, promoting, and publishing search engine-friendly content, such as blog posts, email marketing, online guides, or promotional videos. We aim to engage the target audience with relevant content that answers their questions and earns trust. Our Content specialist helps you develop an effective strategy to attract potential customers and build a lasting impression on them." },
    { label: "Pay-Per-Click", content: "PPC campaigns help businesses generate leads by targeting relevant prospects. By using a Google ad, you can reach people actively searching for what you offer. PPC ads appear when potential customers search for your products on Google." },
    { label: "Social Media Marketing", content: "Social media, being an integral part of people’s lives today, holds exponential benefits for businesses to generate leads and interact with the masses. Our SMM services includes creating effective social media marketing campaigns across various channels such as Facebook, Instagram, LinkedIn, Twitter, and more. Our experts identify your marketing goals, analyze competitors, and evaluate your customers’ behavior to design a tailored approach." },
    { label: "Search Engine Optimization", content: "Search engines are the best gateway to understanding online experiences, which is why SEO services are vital for generating more website traffic, leads, and sales. Our SEO experts perform on-page and off-page optimization and use Google Search Console to track your website’s performance. Being the #1 SEO agency, our services include SEO copywriting, local SEO services, eCommerce SEO services, lead generation SEO, and so on. We aim to attract high-quality leads and traffic and increase conversions with an effective SEO strategy." },
  ];

  const [count, setCount] = useState(0); // Initialize state to 0
  const maxCount = 3500; // Set maximum count

  useEffect(() => {
    // Set up a timer that increases the count every 1 second (1000 ms)
    const interval = setInterval(() => {
      setCount((prevCount) => {
        // Check if the count has reached the maximum value
        if (prevCount >= maxCount) {
          clearInterval(interval); // Clear the interval if max count is reached
          return prevCount; // Return the previous count to stop incrementing
        }
        return prevCount + 1; // Increment count by 1
      });
    }, 10);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // 

  return (

    <div>
      
      <div className="container-digital">
        <img src={DM} alt="Digital Marketing Banner" className="digital-img" />
        <h1 className="digital_banner_title">Full-Service Digital Marketing</h1>
      </div>
      <ClientLogohead />
      <div className='container'>
        <div className="container_digital_ser">
          <div className="images-column">
            <img src={digital1} alt="Image 1" className="image-item" />
          </div>

          <div className="text-column">
            <h1 className='digital-ser-title'>Discover How <span>Digital Marketing Services </span>
              can unlock the potential of
              your business</h1>
            <p className='digital-ser-para'>
              Having a strong online presence is important in today’s digital landscape. Effective online advertising, social media management, and search engine optimization can drive growth 10 times.
            </p>
            <p className='digital-ser-para'>
              All this is only possible by building a strong digital marketing strategy. With the right tools, services, and certified digital marketers, like the ones offered at RIO, you can streamline and enhance your online business’s online presence and target the market effortlessly.
            </p>
            <div className='digit-wrapper'>
              <h5><IoIosStarOutline className='digit-icon' /> No Patchwork Of Design</h5>
              <h5><SlBadge className='digit-icon1' /> Complete Visibility</h5>
              <h5><CiFaceSmile className='digit-icon2' /> Happy Clients</h5>
              <h5><MdOutlineCheckCircle className='digit-icon3' /> Get Started</h5>
              <button className='digital-button'>
                Get Start <span className='button-icon'><ArrowCircleRightIcon /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='top-ser-bg'>
      <div className="container_digital_ser1">
          <div className="text-column">
          <h5 className='top-ser-subtitle'>Top Services</h5>
          <h2 className='top-ser-title'>Grow And Prosper With Our Experts</h2>
          <p className='top-ser-para'>Looking for a dedicated and passionate team of digital marketers? Then partner with RIO, whose vision is to serve better with strategic analysis, and cutting-edge, creative problem-solving digital tools.</p>
          <p className='work'>Work Completed</p>
          <p className='top-count'>{count}+</p>
          <button className='digital-button1'>
                Contact with us <span className='button-icon'><ArrowCircleRightIcon /></span>
              </button>
          </div>
         <div>
         <section className='digital_wapp'>
         <div className="black digital-grid">
          <img src={bp} alt="" />
          <h5>Branding</h5>
          <p>We help you develop effective communication channels for your business.
          </p>
          </div>
          <div className="black digital-grid">
          <img src={plan} alt="" />
          <h5>Content Writing</h5>
          <p>Our Content specialist helps you develop an effective strategy to attract potential customers.</p>
          </div>
         </section>
         <div  className='digital_logo'><img src={logo} alt=""/></div>
         <section className='digital1_wapp'>
         <div className="black digital1-grid">
          <img src={time} alt="" />
          <h5>SEO</h5>
          <p>Search engines are the best gateway to understanding online experiences, which is why SEO services are vital for generating more website traffic, leads, and sales.
          </p>
          </div>
          <div className="black digital1-grid">
          <img src={sm} alt="" />
          <h5>Social Media</h5>
          <p>Our experts identify your marketing goals, analyze competitors, and evaluate your customers' behavior to design a tailored approach.</p>
          </div>
         </section> 

         </div>
        </div>
      </div>
      <div className="digital-service-bg">
       <div className="container_digital_ser1">
          <div className="text-column">
          <img src={service} alt="Image 1" className="digital-ser-img" />
          <div className="digital-progress"> 
            <div className="progress-bar">

            </div>
            <h4>Client<br/>Satisfied</h4>
          <div className="progress-bar1"></div>
          <h4>Grow<br/>
          Your Business</h4>
          </div>
        
          
          </div>
         <div>
         <section className='digital_ser_wapp'>
         <div className="text-column">
          <h5 className='digital_ser_wapp_subtitle'>Our Service</h5>
          <h2 className='digital_ser_wapp_title'>Digital Marketing</h2>
          <p className='digit-ser-para1'>RIO, a reliable Digital Marketing Agency possesses a team of experienced marketing experts who collaborate with you to understand your business needs and create a brand strategy that builds your brand equity, promotes its growth, and connects with the potential audience.</p>
          <Accordion items={accordionItems} />
          </div>
         </section>
         </div>
        </div>
      </div>
      <div className="container_digital_ser1" style={{marginTop:-140}}>
          <div className="text-column">
          <h5 className='top-ser-subtitle'>What we use</h5>
          <h2 className='top-ser-title'>Tools & Technologies</h2>
          <p className='top-ser-para'>Our tailor-made digital marketing services include SEO, branding, social media marketing, social media engagement, content marketing, PPC advertising, and more. Our team of experts stays up-to-date with the latest trends and technologies to ensure your digital marketing efforts are successful.</p>
          <button className='digital-button1'>
                Contact with us <span className='button-icon'><ArrowCircleRightIcon /></span>
              </button>
          </div>
         <div>
         <section className='digital1_wapp ' style={{marginTop:40}}>
         <div className="digital1-grid2" >
          <img src={Google} alt="" style={{height:50, width:100}} />
          <h6>GOOGLE <br/>ANALYTICS</h6>
            
          
          </div>
          <div className="digital1-grid2">
          <img src={GoogleSearch} alt=""  style={{height:50, width:100}}/>
          <h6>GOOGLE <br/>SEARCH <br/>CONSOLE</h6>
         
          </div>
          <div className="digital1-grid2">
          <img src={ahrefs} alt=""  style={{height:50, width:100}}/>
          <h6>AHREF</h6>
          
          </div>
         </section> 
         {/* <div  className='digital_logo'><img src={logo} alt=""/></div> */}
         <section className='digital1_wapp'>
         <div className="digital1-grid2">
          <img src={Surfer} alt="" style={{height:50, width:100}} />
          <h6>SURFER</h6>
          
          </div>
          <div className="digital1-grid2">
         
          <img src={keyword} alt="" style={{height:70, width:100}} />
          <h6>KEYWORD EVERYWHERE</h6>
          </div>
          <div className="digital1-grid2">
          <img src={semrush} alt="" style={{height:50, width:100}} />
          <h6>SEMRUSH</h6>
          </div>
         </section> 
 
         </div>
        </div>
        <HomeContact/> 
     
    </div>
  );
}

export default Digitalmarketing;
