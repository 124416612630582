import React from 'react'
import '../../css/Team.css';
import Tony from '../../assets/images/tony.jpeg';
import Kavith from '../../assets/images/kavitha-mam-123.jpg';
import Shilpa from '../../assets/images/shilpa.jpeg';
import {Link} from 'react-router-dom'

function Team() {
  return (
    <div className='col-12'>
           <h1 className='team'>The Team That Makes It Happen!</h1>
               <p className='team_des'>Our team is made up of talented and well-skilled individuals 
                   who are passionate and committed to providing our clients with the best 
                    possible services and solutions. We always look for ways to improve our 
                    solutions and services, so our clients get nothing but the best.</p>
   
        <div className='team_contanier'>
          <div className="container">
            <div className="row">
              <div className='col-lg-4 col-md-6 '>

              <div className='card__article '>
                    <img src={Kavith} alt='' className='team_img'/>
                   <div className='card_data_overlay '>
                   <Link to ="https://www.linkedin.com/in/kavitha-sylvia-a26168202/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Kavitha Sylvia</h4>
                      <span>CFO</span></Link> 
                   </div>
              </div>
              </div>
              <div className='col-lg-4 col-md-6 text-align-center'>

                <div className='card__article '>
                  <img src={Tony} alt='' className='team_img'/>
                      <div className='card_data_overlay '>
                      <Link to ="https://www.linkedin.com/in/tony-rozario/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Tony Rozario</h4>
                        <span>CEO</span></Link>
                      </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>

              <div className='card__article '>
               <img src={Shilpa} alt='' className='team_img'/>
                <div className='card_data_overlay '>
                <Link to ="https://www.linkedin.com/in/j-maria-shilpa-2ab9a71b9/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Maria Shilpa</h4>
                    <span>COO</span></Link>
                </div>
                </div>
              </div>
            </div>
             </div>
          
         
        
            
        </div>
    </div>
  )
}

export default Team