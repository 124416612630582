import React from 'react'
import webbanner from '../../assets/images/web-banner.jpg'
import ClientLogohead from '../Home/ClientLogohead'
import '../../css/WebDevelopment.css'
import webbanner2 from "../../assets/images/Web-development.jpg"

const WebDevelopment = () => {
  return (
    <div>
        <div className='web-banner'>
            <img className='web-img' src={webbanner} alt="" />
            <div className="w-t-con">
            <div className='web-banner-title-1'>Vision into Reality With</div>
            <div className='web-banner-title-2'>Custom Web</div>
            <div className='web-banner-title-3'>Development</div>
            </div>
            <ClientLogohead/>
            <hr className='b-line' />
            <div className="web-container">
      <div className="web-content">
        <h1 className='web-head'>
          Take your business to new heights with professional{' '}
          <span className="highlight">Web Development Services!</span>
        </h1>
        <p className='web-phara'>
          Having a strong online presence is important in today’s digital
          landscape. Effective online advertising, social media management, and
          search engine optimization can drive growth 10 times. Moreover, it
          helps engage with customers, reach new audiences, and increase
          revenue.
        </p>
        <p>
          All this is only possible by building a strong digital marketing
          strategy. With the right tools, services, and certified digital
          marketers, like the ones offered at RIO, you can streamline and
          enhance your online business's online presence and target the market
          effortlessly.
        </p>
        <div className="features">
          <div className="feature">No Patchwork of Design</div>
          <div className="feature">Complete Visibility</div>
          <div className="feature">Happy Clients</div>
          <div className="feature">Healthier Business</div>
        </div>
        <button className="get-started">GET STARTED</button>
      </div>
      <div className="image-section">
        <img src={webbanner2} alt="Web Development" className="image"/>
      </div>
    </div>
        </div>
    </div>
  )
}

export default WebDevelopment