import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import staffing from '../../assets/images/Satffing.jpg'
import '../../css/Staffing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Divider from '@mui/material/Divider';
import ClientLogohead from '../Home/ClientLogohead'
import ST from '../../assets/images/Short-Term-2.jpg'
import LT from '../../assets/images/temp-hire-1.png'
import TH from '../../assets/images/Tem-hire.jpg'
import DH from '../../assets/images/Direct-hire.jpg'
import FH from '../../assets/images/Fresher-Hiring.jpg'
import LH from '../../assets/images/Lateral-Hiring.jpg'
import EH from '../../assets/images/Executive-Hiring.jpg'
import CS from '../../assets/images/Contractual Staffing.jpg'
import rating from '../../assets/images/flaticon-rating.png'
import smiley from '../../assets/images/smiley.png'
import badge from '../../assets/images/badge.png'
import logo from '../../assets/images/riologo.png'
import { FaRegHandPointer } from "react-icons/fa6";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import HomeContact from '../Home/HomeContact';
function Staffing() {
  return (
    <div>
      
    <div className='staff'>
    {/* <FontAwesomeIcon icon="fa-thin fa-hand-point-up" /> */}
      <img src={staffing} alt=""  className='staffing-img'/>
      <h1 className='staff_banner_title'> Build Your Dream Team! Elevate Your
      Business!</h1>
      {/* <div className="staff_circle">
        <div className="staff_hand_icon">
        <FaRegHandPointer/>
          </div>  
      <div className="circle_text">
        <p>Get In Touch - Get In Touch - Get In Touch -</p>
      </div>
      

      </div> */}
     
      

    </div>
    <ClientLogohead/>
    <div className="container">
    <Divider className="divider-staff"/>
    </div>
   
   
    <h1 className='staff_title' >How Comprehensive Staffing Solutions helps your
    business flourish?</h1>
    <p className='staff_des1'>Access to Top Talent: Gain access to our extensive network of skilled professionals across various industries and disciplines.</p>
    <p className='staff_des'>Expert Matching: Our rigorous screening and vetting process ensures that we match you with candidates who not only have the right skills but also fit seamlessly into your company culture.</p>
    <p className='staff_des'>Streamlined Hiring Processes: Save time and resources with our efficient and hassle-free hiring processes, allowing you to focus on your core business priorities.</p>
   <h1  className='staff_title1' >
   Here’s how we help you recruit the best talent!
   </h1>
      <div className='staff_bg'>
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={ST} alt=""  className='staff_img_top' />
            <div className='staff_card'>
            <h1>Short-Term Staffing</h1>
            <p>Providing flexible staffing solutions to meet short-term needs, cover seasonal fluctuations, or address temporary project demands. Access skilled professionals without long-term commitment.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={LT} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>Long-Term-Staffing</h1>
              <p>Expertly matching candidates with permanent positions within client organizations. Streamlining the hiring process to find the ideal fit for long-term success.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={TH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Temp-to-Hire</h1>
            <p>Offering a trial period where clients can assess candidates in real-world work environments before making a permanent hiring decision. Minimize risks and ensure compatibility.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={DH} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>Direct Hire</h1>
              <p>Identifying and recruiting top talent for direct placement within client companies. Streamlining the hiring process for permanent positions without the interim period of temporary employment.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={FH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Fresher's Hiring</h1>
            <p>Tap into a pool of enthusiastic fresh graduates ready to bring new perspectives and energy to your organization. We identify and place promising newcomers to help you build a strong, dynamic team.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={LH} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>Lateral Hiring</h1>
              <p>Expertly matching candidates with permanent positions within client organizations. Streamlining the hiring process to find the ideal fit for long-term success.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={EH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Executive Hiring</h1>
            <p>Providing flexible staffing solutions to meet short-term needs, cover seasonal fluctuations, or address temporary project demands. Access skilled professionals without long-term commitment.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={CS} alt=""  className='staff_img_bottom' />
            
            <div className="staff_card_right">
              <h1>Contractual Staffing</h1>
              <p>Contractual staffing solutions are designed to offer you the flexibility to scale your workforce based on the demands of your business. Whether you need expertise for a short-term project, a long-term assignment, or anything in between, we have the right talent to match your requirements.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div>     
        </div>
            <div className='staff_title'>
              <h1>What makes us stand apart?</h1>
            </div>
         <section className='staff_wapp'>
         <div className="black staff-grid">
          <img src={rating} alt="" />
          <h5>Industry Expertise</h5>
          <p>With years of experience in the staffing industry, RIO understands the nuances of different sectors and can provide you with tailored staffing solutions to meet your organization's needs.</p>
          </div>
          <div className="black staff-grid">
          <img src={rating} alt="" />
          <h5>Personalized Approach</h5>
          <p>We believe in building long-term partnerships with our clients and take the time to understand their unique requirements to deliver personalized staffing solutions that align with their goals.</p>
          </div>


         </section>
         <div  className='staff_logo'><img src={logo} alt=""/></div>
         
         <section className='staff_wapp1'>
         <div className="black staff-grid">
          <img src={badge} alt="" />
          <h5>Quality Assurance</h5>
          <p>With years of experience in the staffing industry, RIO understands the nuances of different sectors and can provide you with tailored staffing solutions to meet your organization's needs.</p>
          </div>
          <div className="black staff-grid">
          <img src={smiley} alt="" />
          <h5>Customer Satisfaction</h5>
          <p>We believe in building long-term partnerships with our clients and take the time to understand their unique requirements to deliver personalized staffing solutions that align with their goals.</p>
          </div>


         </section>

           <HomeContact/>
         
       
       
   </div>
    
    
  )
}

export default Staffing