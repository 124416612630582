import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/Homebanner.css';

import banner from '../../assets/images/banner-1.png';
// import SendIcon from '@mui/icons-material/Send';


import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
// import Button from '@mui/material/Button';

function Homebanner() {
  return (
    
   <div>
     <div className='banner'>
      
      <div className="left-column">
       <h1 className='heading-homebanner'>
        End-to-End<br/>
        <span>Business Solutions</span> <br/>
        <span>Provider</span>
        </h1>
        <p className='description'>Your Business, Your Queries, Our Expertise, Our <br/>
        Innovative Solutions: Let Us Help You Succeed!
        
        </p>
        <button className='banner-button'>Let's Connect
  <span className='button-icon'><ArrowCircleRightIcon/></span>  
</button>
      </div> 

      <div className="right-cloumn">
      <img src={banner} alt=""  className='bannerimage'/>
      </div>
      
    </div>

     <div className='banner-mob'>
      
      
      <img src={banner} alt=""  className='bannerimage-mob'/>
       

    
     
      <h1 className='heading-mob'>
        End-to-End 
        Business Solutions <br/>
        <span>Provider</span>
        </h1>
        <p className='description-mob'>Your Business, Your Queries, Our Expertise, Our 
        Innovative Solutions: Let Us Help You Succeed!
        
        </p>
        <button className='banner-button'>Let's Connect
  <span className='button-icon'><ArrowCircleRightIcon/></span>  
</button>
      
      
    </div> 

   </div>
    
    
  )
}

export default Homebanner